<template>
  <section>
    <div class="text-center">
      <h1 class="mt-5">
        Pagamento via PicPay
      </h1>

      <p class="mb-2 pb-75">
        Copie o nosso nome de usuário no PicPay e envie o valor desejado.
        <br>
        Quando terminar de fazer a transferência, envie o comprovante para a gente!
      </p>
    </div>

    <b-row>
      <b-col
        cols="12"
        class="d-flex justify-content-center"
      >
        <b-card>
          <b-row>
            <b-col cols="12">
              <CopyPicPayCode />
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <b-col
        cols="12"
        class="d-flex justify-content-center"
      >
        <b-button
          class="mt-2"
          variant="relief-primary"
          @click.stop="open()"
        >
          Já fez o pagamento? Envie o comprovante clicando aqui!
        </b-button>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BCard, BRow, BCol, BButton
} from 'bootstrap-vue'
import CopyPicPayCode from './CopyPicPayCode.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    CopyPicPayCode
  },
  data: () => ({
    userData: JSON.parse(localStorage.getItem('userData'))
  }),
  methods: {
    open () {
      // window.open(`https://api.whatsapp.com/send?phone=5521969315819&text=%F0%9F%92%B0%20%7C%20*Ol%C3%A1!%20Pagamento%20efetuado!*%20%E2%9C%85%0A%0A%0A%F0%9F%A7%BE%20%7C%20*Segue%20meu%20comprovante%20e%20minhas%20informa%C3%A7%C3%B5es!*%20%0A%F0%9F%8F%A6%20%7C%20M%C3%A9todo%20utilizado:%20*PicPay*%0A%F0%9F%8C%90%20%7C%20*Compra%20feita%20atrav%C3%A9s%20do%20site:%20contasinfinity.com.br*%0A%0A%E2%84%B9%EF%B8%8F%20%7C%20*Estarei%20informando%20meu%20Email%20e%20enviando%20o%20comprovante%20/%20print%20do%20pagamento*.%0A%E2%9C%89%EF%B8%8F%20%7C%20*Meu%20Email%20de%20cadastro%20%C3%A9*:%20${this.userData.email}`)
      this.$router.push({ name: 'Enviar Comprovante (PicPay)' })
    }
  }
}
</script>
